import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import footerRoutes from "footer.routes";

// Images
import Navbar from "components/NavBar";
import consumerRoutes from "../../consumerRoutes";

import ReactJson from "react-json-view";

import MKBadge from "components/MKBadge";
import { BackButton } from "components/BackButton";
import { BackToTop } from "components/BackToTop";
import { Icon } from "@mui/material";

export const WhatData = () => {
  const location = {
    accuracy: 5,
    altitude: 0,
    altitudeAccuracy: -1,
    heading: 350.51,
    latitude: 37.50532023,
    longitude: -122.33564227,
    speed: 35.11,
    timestamp: 1711308855,
  };

  const microphone = {
    dBFS: -120,
    timestamp: 1711141471,
  };

  const accelerometer = {
    x: 35.11,
    y: -15.1133,
    z: 0.23244,
    timestamp: 1711308855,
  };
  const pedometer = {
    steps: 125,
    timestamp: 1711308855,
  };
  const barometer = {
    pressure: 1013.25,
    relativeAltitude: 1.23,
    timestamp: 1711308855,
  };
  const deviceMotion = {
    acceleration: { x: 1, y: 0.3, z: -3.25 },
    accelerationIncludingGravity: { x: 1, y: 0.3, z: -3.25 },
    orientation: 0,
    rotation: { alpha: 3.223, beta: 0.2233, gamma: -3.44 },
    rotationRate: { alpha: 3.223, beta: 0.2233, gamma: -3.44 },
    timestamp: 1711308855,
  };
  const magnetometer = {
    x: 35.11,
    y: -15.1133,
    z: 0.23244,
    timestamp: 1711308855,
  };
  const gyroscope = {
    x: 35.11,
    y: -15.1133,
    z: 0.23244,
    timestamp: 1711308855,
  };
  const light = {
    illuminance: 0.234455,
    timestamp: 1711308855,
  };
  const carrier = {
    carrierName: "AT&T",
    allowsVOIP: true,
    isoCountryCode: 65535,
    mobileCountryCode: 65535,
    mobileNetworkCode: 65535,
    mobileNetworkOperator: 65535,
    timestamp: 1711308855,
  };
  const compass = {
    accuracy: 2,
    magHeading: 23.234323,
    trueHeading: 23.54644,
    timestamp: 1711308855,
  };
  const installedApps = {
    amount: 123,
    list: ["List of installed apps"],
    timestamp: 1711308855,
  };
  return (
    <>
      <Navbar
        routes={consumerRoutes}
        action={{
          type: "internal",
          route: "/consumer/buyData",
          label: "buy data",
          color: "info",
        }}
        sticky
      />
      <Container>
        <MKBox paddingTop={15}>
          <BackButton />
        </MKBox>
        <Grid container flexDirection="column" mt={4} spacing={2}>
          <Grid item lg={6} xs={12} sx={{ textAlign: "center", mx: "auto" }}>
            <MKBadge
              variant="contained"
              color="info"
              badgeContent="Bripplex"
              container
              circular
              sx={{ mb: 1 }}
            />
            <MKTypography variant="h2" mb={1}>
              What data we collect?{" "}
            </MKTypography>
            <MKTypography variant="body" color="text">
              While the Bripplex app is active and you have authorized them, your device sensors
              will collect and submit data.
            </MKTypography>
          </Grid>

          <Grid item textAlign={"left"} xs={12} marginLeft={2} marginRight={1}>
            <MKTypography variant="h3" mt={4}>
              <Icon>location_on</Icon> {` `}Location
            </MKTypography>
            <MKTypography variant="body" mb={4}>
              Latitude and Longitude coordinates. Including altitude, heading and speed.
            </MKTypography>
            <MKTypography variant="body" mb={3}>
              <ul>
                <li>
                  <b>Accuracy</b>: The radius of uncertainty for the location, measured in meters.
                </li>
                <li>
                  <b>Altitude</b>: The altitude in meters above the WGS 84 reference ellipsoid.
                </li>
                <li>
                  <b>Altitude accuracy</b>: The accuracy of the altitude value, in meters.
                </li>
                <li>
                  <b>Heading</b>: Horizontal direction of travel of this device, measured in degrees
                  starting at due north and continuing clockwise around the compass. Thus, north is
                  0 degrees, east is 90 degrees, south is 180 degrees, and so on.
                </li>
                <li>
                  <b>Latitude</b>: The latitude in degrees.
                </li>
                <li>
                  <b>Longitude</b>: The longitude in degrees.
                </li>
                <li>
                  <b>Speed</b>: The instantaneous speed of the device in meters per second.
                </li>
                <li>
                  <b>Timestamp</b>: The epoch time of when the data was measured.
                </li>
              </ul>
            </MKTypography>

            <MKTypography variant="body" mb={1}>
              Sample data:
            </MKTypography>
            <ReactJson src={location} name={"Location"} style={{ fontSize: 15, marginTop: 15 }} />
            <BackToTop />
          </Grid>

          {/* microphone */}
          <Grid item textAlign={"left"} xs={12} marginLeft={2} marginRight={1}>
            <MKTypography variant="h3" mt={4}>
              <Icon>mic</Icon>
              {` `}Microphone
            </MKTypography>
            <MKTypography variant="body" mb={4}>
              Surrounding loudness in dB.
            </MKTypography>
            <MKTypography variant="body" mb={4}>
              <ul>
                <li>
                  <b>dBFS</b>: The most recent reading of the loudness in dB. The value ranges from
                  –160 dBFS, indicating minimum power, to 0 dBFS, indicating maximum power.
                </li>

                <li>
                  <b>Timestamp</b>: The epoch time of when the data was measured.
                </li>
              </ul>
            </MKTypography>

            <MKTypography variant="body" mb={3}>
              Sample data:
            </MKTypography>
            <ReactJson
              src={microphone}
              name={"Microphone"}
              style={{ fontSize: 15, marginTop: 15 }}
            />
            <BackToTop />
          </Grid>

          {/* Accelerometer */}
          <Grid item textAlign={"left"} xs={12} marginLeft={2} marginRight={1}>
            <MKTypography variant="h3" mt={4}>
              <Icon>all_out</Icon>
              {` `}Accelerometer
            </MKTypography>
            <MKTypography variant="body" mb={4}>
              Measures device acceleration in three-dimensional space, meaning any movement or
              vibration.
            </MKTypography>
            <MKTypography variant="body" mb={4}>
              <ul>
                <li>
                  <b>x</b>: Value of gs device reported in X axis.
                </li>
                <li>
                  <b>y</b>: Value of gs device reported in Y axis.
                </li>
                <li>
                  <b>z</b>: Value of gs device reported in Z axis.
                </li>

                <li>
                  <b>Timestamp</b>: The epoch time of when the data was measured.
                </li>
              </ul>
            </MKTypography>
            <MKTypography variant="body" mb={3}>
              Sample data:
            </MKTypography>
            <ReactJson
              src={accelerometer}
              name={"Accelerometer"}
              style={{ fontSize: 15, marginTop: 15 }}
            />

            <BackToTop />
          </Grid>

          {/* Pedometer */}
          <Grid item textAlign={"left"} xs={12} marginLeft={2} marginRight={1}>
            <MKTypography variant="h3" mt={4}>
              <Icon>directions_walk</Icon> {` `}Pedometer
            </MKTypography>
            <MKTypography variant="body" mb={4}>
              Counts the number of steps taken.
            </MKTypography>
            <MKTypography variant="body" mb={4}>
              <ul>
                <li>
                  <b>steps</b>: Number of steps taken
                </li>
                <li>
                  <b>Timestamp</b>: The epoch time of when the data was measured.
                </li>
              </ul>
            </MKTypography>
            <MKTypography variant="body" mb={3}>
              Sample data:
            </MKTypography>
            <ReactJson src={pedometer} name={"Pedometer"} style={{ fontSize: 15, marginTop: 15 }} />

            <BackToTop />
          </Grid>

          {/* Barometer */}
          <Grid item textAlign={"left"} xs={12} marginLeft={2} marginRight={1}>
            <MKTypography variant="h3" mt={4}>
              <Icon>air</Icon> {` `}Barometer
            </MKTypography>
            <MKTypography variant="body" mb={4}>
              Measures pressure in hectopascals (hPa).
            </MKTypography>
            <MKTypography variant="body" mb={4}>
              <ul>
                <li>
                  <b>pressure</b>: Measurement in hectopascals (hPa).
                </li>
                <li>
                  <b>relativeAltitude</b>: Measurement in meters (m). Only for IOS.
                </li>
                <li>
                  <b>Timestamp</b>: The epoch time of when the data was measured.
                </li>
              </ul>
            </MKTypography>

            <MKTypography variant="body" mb={3}>
              Sample data:
            </MKTypography>
            <ReactJson src={barometer} name={"Barometer"} style={{ fontSize: 15, marginTop: 15 }} />

            <BackToTop />
          </Grid>

          {/* DeviceMotion */}
          <Grid item textAlign={"left"} xs={12} marginLeft={2} marginRight={1}>
            <MKTypography variant="h3" mt={4}>
              <Icon>settings_overscan</Icon> {` `}Device Motion
            </MKTypography>
            <MKTypography variant="body" mb={4}>
              Measures device motion. All data is presented in terms of three axes that run through
              a device.
            </MKTypography>
            <MKTypography variant="body" mb={4}>
              <ul>
                <li>
                  <b>acceleration</b>: Device acceleration on the three axis as an object with x, y,
                  z keys. Expressed in meters per second squared (m/s^2).
                </li>
                <li>
                  <b>accelerationIncludingGravity</b>: Device acceleration with the effect of
                  gravity on the three axis as an object with x, y, z keys. Expressed in meters per
                  second squared (m/s^2).
                </li>
                <li>
                  <b>orientation</b>: Device orientation based on screen rotation. Value is one of:
                  0 (portrait), 90 (right landscape), 180 (upside down), -90 (left landscape).
                </li>
                <li>
                  <b>rotation</b>: Device&apos;s orientation in space as an object with alpha, beta,
                  gamma keys where alpha is for rotation around Z axis, beta for X axis rotation and
                  gamma for Y axis rotation.
                </li>
                <li>
                  <b>rotationRate</b>: Device&apos;s rate of rotation in space expressed in degrees
                  per second (deg/s).
                </li>
                <li>
                  <b>Timestamp</b>: The epoch time of when the data was measured.
                </li>
              </ul>
            </MKTypography>

            <MKTypography variant="body" mb={3}>
              Sample data:
            </MKTypography>
            <ReactJson
              src={deviceMotion}
              name={"DeviceMotion"}
              style={{ fontSize: 15, marginTop: 15 }}
            />

            <BackToTop />
          </Grid>

          {/* Magnetometer */}
          <Grid item textAlign={"left"} xs={12} marginLeft={2} marginRight={1}>
            <MKTypography variant="h3" mt={4}>
              <Icon>unfold_less_double</Icon> {` `}Magnetometer
            </MKTypography>
            <MKTypography variant="body" mb={4}>
              Measures changes in the magnetic field specified in microtesla (μT).
            </MKTypography>
            <MKTypography variant="body" mb={4}>
              <ul>
                <li>
                  <b>x</b>: Value representing strength of magnetic field recorded in X axis.
                </li>
                <li>
                  <b>y</b>: Value representing strength of magnetic field recorded in Y axis.
                </li>
                <li>
                  <b>z</b>: Value representing strength of magnetic field recorded in Z axis.
                </li>
                <li>
                  <b>Timestamp</b>: The epoch time of when the data was measured.
                </li>
              </ul>
            </MKTypography>

            <MKTypography variant="body" mb={3}>
              Sample data:
            </MKTypography>
            <ReactJson
              src={magnetometer}
              name={"Magnetometer"}
              style={{ fontSize: 15, marginTop: 15 }}
            />

            <BackToTop />
          </Grid>

          {/* Gyroscope */}
          <Grid item textAlign={"left"} xs={12} marginLeft={2} marginRight={1}>
            <MKTypography variant="h3" mt={4}>
              <Icon>open_with</Icon> {` `}Gyroscope
            </MKTypography>
            <MKTypography variant="body" mb={4}>
              Represents the rotation along that particular axis measured in degrees per second
              (°/s).
            </MKTypography>
            <MKTypography variant="body" mb={4}>
              <ul>
                <li>
                  <b>x</b>: Value of rotation in degrees per second device reported in X axis.
                </li>
                <li>
                  <b>y</b>: Value of rotation in degrees per second device reported in Y axis.
                </li>
                <li>
                  <b>z</b>: Value of rotation in degrees per second device reported in Z axis.
                </li>
                <li>
                  <b>Timestamp</b>: The epoch time of when the data was measured.
                </li>
              </ul>
            </MKTypography>

            <MKTypography variant="body" mb={3}>
              Sample data:
            </MKTypography>
            <ReactJson src={gyroscope} name={"Gyroscope"} style={{ fontSize: 15, marginTop: 15 }} />

            <BackToTop />
          </Grid>

          {/* Light */}
          <Grid item textAlign={"left"} xs={12} marginLeft={2} marginRight={1}>
            <MKTypography variant="h3" mt={4}>
              <Icon>flare</Icon> {` `}Light
            </MKTypography>
            <MKTypography variant="body" mb={4}>
              Measures ambient light.
            </MKTypography>
            <MKTypography variant="body" mb={4}>
              <ul>
                <li>
                  <b>illuminance</b>: Ambient light level registered by the device measured in lux
                  (lx). Only in Android.
                </li>

                <li>
                  <b>Timestamp</b>: The epoch time of when the data was measured.
                </li>
              </ul>
            </MKTypography>

            <MKTypography variant="body" mb={3}>
              Sample data:
            </MKTypography>
            <ReactJson src={light} name={"Light"} style={{ fontSize: 15, marginTop: 15 }} />

            <BackToTop />
          </Grid>

          <Grid item textAlign={"left"} xs={12} marginLeft={2} marginRight={1}>
            <MKTypography variant="body" mb={3}>
              All recorded sensors data is grouped under the device current <b>location</b>{" "}
              tracking.
            </MKTypography>
          </Grid>
        </Grid>

        {/* Carrier */}
        {/* <Grid item textAlign={"left"} xs={12} marginLeft={2} marginRight={1}>
          <MKTypography variant="h3" mt={4}>
            <Icon>cell_tower</Icon> {` `}Carrier
          </MKTypography>
          <MKTypography variant="body2" mb={4} color={"error"}>
            <Icon color="error">grade</Icon> Recently added!
          </MKTypography>
          <MKTypography variant="body" mb={4}>
            Get carrier and cellular service provider information.
          </MKTypography>
          <MKTypography variant="body" mb={4}>
            <ul>
              <li>
                <b>Carrier Name</b>: name of the carrier of the device.
              </li>
              <li>
                <b>allowsVOIP</b>: indicates if the carrier allows VoIP calls to be made on its
                network.
              </li>
              <li>
                <b>isoCountryCode</b>: The ISO country code for the user&apos;s cellular service
                provider.
              </li>
              <li>
                <b>mobileCountryCode</b>: The mobile country code (MCC) for the user&apos;s cellular
                service provider.
              </li>
              <li>
                <b>mobileNetworkCode</b>: The mobile network code (MNC) for the user&apos;s cellular
                service provider.
              </li>
              <li>
                <b>mobileNetworkOperator</b>: The MCC + MNC, e.g 46697
              </li>

              <li>
                <b>Timestamp</b>: The epoch time of when the data was measured.
              </li>
            </ul>
          </MKTypography>
          <MKTypography variant="body" mb={4}>
            Sample data:
          </MKTypography>
          <ReactJson src={carrier} name={"Carrier"} style={{ fontSize: 15, marginTop: 15 }} />
          <BackToTop />
        </Grid> */}

        {/* Compass */}
        <Grid item textAlign={"left"} xs={12} marginLeft={2} marginRight={1}>
          <MKTypography variant="h3" mt={4}>
            <Icon>explore</Icon> {` `}Compass
          </MKTypography>
          <MKTypography variant="body2" mb={4} color={"error"}>
            <Icon color="error">grade</Icon> Recently added!
          </MKTypography>
          <MKTypography variant="body" mb={4}>
            Get heading in magnetic and true north degrees.
          </MKTypography>
          <MKTypography variant="body" mb={4}>
            <ul>
              <li>
                <b>Accuracy</b>: 3: high accuracy, 2: medium accuracy, 1: low accuracy, 0: none
                Reference for iOS <br />
                3: {`<`} 20 degrees uncertainty, 2: {`<`} 35 degrees, 1: {`<`} 50 degrees, 0: {`>`}{" "}
                50 degrees for Android.
              </li>
              <li>
                <b>Magnetic Heading</b>: Measure of magnetic north in degrees.
              </li>
              <li>
                <b>True Heading</b>: Measure of true north in degrees.
              </li>
              <li>
                <b>Timestamp</b>: The epoch time of when the data was measured.
              </li>
            </ul>
          </MKTypography>
          <MKTypography variant="body" mb={4}>
            Sample data:
          </MKTypography>
          <ReactJson src={compass} name={"Compass"} style={{ fontSize: 15, marginTop: 15 }} />
          <BackToTop />
        </Grid>

        {/* InstalledApps */}
        {/* <Grid item textAlign={"left"} xs={12} marginLeft={2} marginRight={1}>
          <MKTypography variant="h3" mt={4}>
            <Icon>apps</Icon> {` `}Installed Apps
          </MKTypography>
          <MKTypography variant="body2" mb={4} color={"error"}>
            <Icon color="error">grade</Icon> Recently added!
          </MKTypography>
          <MKTypography variant="body" mb={4}>
            Gets the list of installed apps on the device.
          </MKTypography>
          <MKTypography variant="body" mb={4}>
            <ul>
              <li>
                <b>Amount</b>: number that indicates how many apps are installed.
              </li>
              <li>
                <b>List</b>: An array of strings with the list of applications installed on the
                device
              </li>
              <li>
                <b>Timestamp</b>: The epoch time of when the data was measured.
              </li>
            </ul>
          </MKTypography>
          <MKTypography variant="body" mb={4}>
            Sample data:
          </MKTypography>
          <ReactJson
            src={installedApps}
            name={"installedApps"}
            style={{ fontSize: 15, marginTop: 15 }}
          />
          <BackToTop />
        </Grid> */}

        {/* Wifi */}
        {/* <Grid item textAlign={"left"} xs={12} marginLeft={2} marginRight={1}>
          <MKTypography variant="h3" mt={4}>
            <Icon>wifi</Icon> {` `}WiFi strength
          </MKTypography>
          <MKTypography variant="body2" mb={4} color={"error"}>
            <Icon color="error">announcement</Icon> Comming soon!
          </MKTypography>
          <MKTypography variant="body" mb={4}>
            Measures Wifi strength at the current location
          </MKTypography>

          <BackToTop />
        </Grid> */}

        {/* Upload/Download speed */}
        {/* <Grid item textAlign={"left"} xs={12} marginLeft={2} marginRight={1}>
          <MKTypography variant="h3" mt={4}>
            <Icon>speed</Icon> {` `}Upload / Download speed
          </MKTypography>
          <MKTypography variant="body2" mb={4} color={"error"}>
            <Icon color="error">announcement</Icon> Comming soon!
          </MKTypography>
          <MKTypography variant="body" mb={4}>
            Measures your Upload and Download speed at your current location.
          </MKTypography>

          <BackToTop />
        </Grid> */}

        <Grid item textAlign={"left"} xs={12} marginLeft={2} marginRight={1} my={10}>
          <MKTypography variant="body" mb={3}>
            All recorded sensors data is grouped under the device current <b>location</b> tracking.
          </MKTypography>
        </Grid>
      </Container>
      <MKBox pt={6} px={1}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};
